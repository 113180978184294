.inactive-status-indicator {
	height: 10px;
	width: 10px;
	border-radius: 50%;
	color: orangered;
}

.active-status-indicator {
	height: 10px;
	width: 10px;
	border-radius: 50%;
	color: limegreen;
}

.label_style {
	font-weight: 600;
	font-size: 20px;
}

.MTableHeader-header-13 {
	color: white !important;
	background-color: #0a66c2 !important;
}
.MuiTableSortLabel-root.MuiTableSortLabel-active {
	color: white !important;
}
.MuiTableSortLabel-active .MuiTableSortLabel-icon {
	color: white !important;
}
.MuiButtonBase-root.MuiTableSortLabel-root::hover {
	color: white !important;
}
.MuiTableSortLabel-icon {
	color: white !important;
}
[data-rbd-drag-handle-context-id='1'] {
	color: white !important;
}
.inner_space {
	padding: 20px;
	/* background-color: white; */
	box-shadow: 1px 6px 10px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation2 {
	 box-shadow: none !important;
	/*box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);*/
}
.MTableToolbar-title-9 {
	display: none;
}
.MuiTableRow-head {
	box-shadow: 0 1px 11px 6px rgba(0, 0, 0, 0.12);
	height: 4em;
}
hr {
	border-top: 2px solid #c1c1c1;
	margin-bottom: 1rem;
}
.MuiTableSortLabel-root {
	font-weight: 600;
}
.MuiTableCell-paddingCheckbox:last-child {
	padding-right: 16px !important;
	width: 13rem !important;
}
.MuiTableCell-paddingNone div {
	display: block !important;
	text-align: center;
}
/*.MuiToolbar-gutters {
	padding-left: 8px !important;
}*/

.MuiCheckbox-colorSecondary.Mui-checked {
	color: #757575 !important;
}
.Component-paginationSelectRoot-4 {
	position: absolute !important;
}
.MuiTableCell-head {
	background-color: #0a66c2 !important;
	color: white !important;
	line-height: 1rem;
}
.MTableToolbar-actions-21 {
	color: black !important;
}

.MuiGrid-spacing-xs-4 {
	margin: 0px 0px 0px -14px !important;
}

.MuiTab-textColorPrimary.Mui-selected {
	color: #0b66c2 !important;
}

.PrivateTabIndicator-colorPrimary-16 {
	background-color: #0b66c2 !important;
}
.MuiTableCell-root{
    font-family:Lato !important;
    font-size: .75rem!important;
    padding: 3px 5px 3px 5px!important;
}
.MuiTableCell-stickyHeader{
    font-weight:bold!important;
}

@media only screen and (min-width: 2500px) {
  .MuiTableCell-root {
    font-size:1.1rem!important;
  }
}

.CookieConsent div:first-child {
    margin:5px!important;
    text-align:center;
}

@media only screen and (max-width: 424px) {
  .CookieConsent div:first-child{
    flex:140px!important;
    margin:2px 12px 12px!important;
  }
}

.cookieButton:hover {
    box-shadow: 0 0px 9px 0 rgba(0,0,0,.6)!important;
    transform: scale(1.1);
}