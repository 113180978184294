html {
	height: 100%;
}

body {
	font-family: 'Lato', sans-serif !important;
	height: 100%;
}
.MuiButton-root {
	font-family: Lato !important;
}
a {
	color: #0a66c2;
	text-decoration: none!important;
}
a:hover {
	color: #005fbe;
	text-decoration: revert;
}
.MuiMobileStepper-root {
	display: flex;
	padding: 8px;
	background: #fff;
	align-items: center;
	flex-direction: row;
	justify-content: center !important;
}
.MuiMobileStepper-dotActive {
	background-color: #0a66c2 !important;
}
.Toastify__toast--info {
	background: #0a66c2 !important;
}
.MuiSwitch-colorPrimary.Mui-checked {
	color: #0a66c2 !important;
}
.MuiFormLabel-root.Mui-focused {
	color: #0a66c2 !important;
}
.MuiInput-underline:after {
	border-bottom: 2px solid #0a66c2 !important;
}
.MuiInput-underline.Mui-error:after {
	border-bottom: 2px solid red !important;
}
.PrivateTabIndicator-colorPrimary-38 {
	background-color: #0a66c2 !important;
}
.PrivateTabIndicator-colorPrimary-453 {
	background-color: #0a66c2 !important;
}
.Toastify__toast-container--top-right {
	top: 0 !important;
}
.css-26l3qy-menu {
	z-index: 600 !important;
}
.makeStyles-field-28 {
	border-radius: 5px !important;
}
.MuiInputBase-root-56 {
	font-family: 'Lato', sans-serif !important;
	font-size: 14px !important;
}
.MuiOutlinedInput-notchedOutline-50 {
	right: -1px !important;
	top: -6px !important;
	left: -1px !important;
	bottom: -1px !important;
	border-width: 1px !important;
}
.MuiInputBase-input-64 {
	letter-spacing: 0.01071em !important;
	padding: 18.5px 10px !important;
}

.css-1uccc91-singleValue:hover {
	white-space: break-spaces !important;
}

/*Ribbon Section*/
.ribbon {
	width: 30em;
	padding: 0px 0px 15px 0px;
	height: auto;
	margin: 2em auto 0px;
	position: relative;
	color: #fff;
	font: 28px/60px sans-serif;
	text-align: center;
	text-transform: uppercase;
	background: #0a66c2;
	margin-bottom: 3em;
	box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.15);
}

.ribbon i {
	position: absolute;
}

.ribbon i:first-child,
.ribbon i:nth-child(2) {
	position: absolute;
	left: -20px;
	bottom: -20px;
	z-index: -1;
	border: 20px solid transparent;
	border-right-color: #043140;
}

.ribbon i:nth-child(2) {
	left: auto;
	right: -20px;
	border-right-color: transparent;
	border-left-color: #043140;
}

.ribbon i:nth-child(3),
.ribbon i:last-child {
	width: 20px;
	bottom: -20px;
	left: -40px;
	z-index: -2;
	border: 30px solid #0a66c2;
	border-left-color: transparent;
}

.ribbon i:last-child {
	bottom: -20px;
	left: auto;
	right: -40px;
	border: 30px solid #0a66c2;
	border-right-color: transparent;
}

@media only screen and (max-width: 920px) {
	.ribbon {
		width: 20em;
	}
}
@media only screen and (max-width: 640px) {
	.ribbon {
		width: 10rem;
		font-size: 22px !important;
		line-height: 24px;
	}
}
@media only screen and (min-width: 1900px) {
	.ribbon {
		font-size: 42px !important;
		line-height: 6rem;
	}
}

@media (min-width: 1280px) {
	.MuiContainer-maxWidthLg {
		max-width: 1580px !important;
	}
}

/*Ribbon Section ends*/

/*Scroll Bar*/
/* width */
::-webkit-scrollbar {
	width: 0.75rem;
	height: 0.5rem;
}
@media (max-width: 426px) {
	::-webkit-scrollbar {
		width: 0 !important;
		height: 0 !important;
	}
}

/* Track */
::-webkit-scrollbar-track {
	background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #2c87e3;
	border-radius: 0.375rem;
	border-right: 3px solid white;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #0a66c2;
}

/*Placeholder*/
.css-1wa3eu0-placeholder {
	color: #565656!important;
}
.MuiPaper-root {
	background-color: white!important;
	background: white!important;
}

/*Dialog Box*/
@media (max-width: 515px) {
   .MuiDialog-paperWidthSm {
        width: 93%!important;
        max-width: 93%!important;
        margin: 0!important;
   }
}